<template>
  <div style="background-color: #f8f8f8">
    <Header ref="header"/>

    <div class="article-index">
      <div class="w w1200">

        <div class="demo ui-sortable content">

          <div class="visual-item">

            <div class="view">
              <div class="article-col-2 clearfix">
                <div class="article-box">
                  <div class="ab-hd">
                    <h2><i class="iconfont icon-icon02"></i>公司动态</h2>
                    <router-link :to="{path:'/article/page',query:{categoryId:''}}" tag="a" class="more">more</router-link>
                  </div>
                  <div class="ab-bd">
                    <div class="focus" v-if="list1.length > 0">
                      <router-link :to="{path:'/article/view',query:{id:list1[0].id}}" target="_blank" tag="a" class="img">
                        <img :src="getCoverImage(list1[0].images)" alt="">
                      </router-link>
                      <div class="info">
                        <div class="info-name">
                          <router-link :to="{path:'/article/view',query:{id:list1[0].id}}" target="_blank" tag="a">
                            {{list1[0].title}}}
                          </router-link>
                        </div>
                        <div class="info-intro">{{list1[0].abs}}</div>
                        <div class="info-time">{{list1[0].createTime}}</div>
                      </div>
                    </div>
                    <ul class="list" v-for="(item, index) in list1.slice(1)" :key="index">
                      <li>
                        <router-link :to="{path:'/article/view',query:{id:item.id}}" target="_blank" tag="a">
                          {{item.title}}
                        </router-link>
                        <span class="time">{{item.createTime}}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="article-box">
                  <div class="ab-hd">
                    <h2><i class="iconfont icon-icon02"></i>行业资讯</h2>
                    <router-link :to="{path:'/article/page',query:{categoryId:''}}" tag="a" class="more">more</router-link>
                  </div>
                  <div class="ab-bd">
                    <div class="focus" v-if="list2.length > 0">
                      <router-link :to="{path:'/article/view',query:{id:list2[0].id}}" target="_blank" tag="a" class="img">
                        <img :src="getCoverImage(list2[0].images)" alt="">
                      </router-link>
                      <div class="info">
                        <div class="info-name">
                          <router-link :to="{path:'/article/view',query:{id:list2[0].id}}" target="_blank" tag="a">
                            {{list2[0].title}}}
                          </router-link>
                        </div>
                        <div class="info-intro">{{list2[0].abs}}</div>
                        <div class="info-time">{{list2[0].createTime}}</div>
                      </div>
                    </div>
                    <ul class="list" v-for="(item, index) in list2.slice(1)" :key="index">
                      <li>
                        <router-link :to="{path:'/article/view',query:{id:item.id}}" target="_blank" tag="a">
                          {{item.title}}
                        </router-link>
                        <span class="time">{{item.createTime}}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="visual-item">

          <div class="view">
            <div class="article-col-3 clearfix">
              <div class="article-box">
                <div class="ab-hd">
                  <h2><i class="iconfont icon-article"></i>配送与支付</h2>
                  <router-link :to="{path:'/article/page',query:{categoryId:''}}" tag="a">more&gt;</router-link>
                </div>
                <div class="ab-bd">
                  <ul class="list" v-for="(item, index) in list3" :key="index">
                    <li>
                      <router-link :to="{path:'/article/view',query:{id:item.id}}" target="_blank" tag="a">
                        {{item.title}}
                      </router-link>
                      <span class="time">{{item.createTime}}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="article-box">
                <div class="ab-hd">
                  <h2><i class="iconfont icon-article"></i>新手上路</h2>
                  <router-link :to="{path:'/article/page',query:{categoryId:''}}" tag="a" class="more">more&gt;</router-link>
                </div>
                <div class="ab-bd">
                  <ul class="list" v-for="(item, index) in list4" :key="index">
                    <li>
                      <router-link :to="{path:'/article/view',query:{id:item.id}}" target="_blank" tag="a">
                        {{item.title}}
                      </router-link>
                      <span class="time">{{item.createTime}}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="article-box">
                <div class="ab-hd">
                  <h2><i class="iconfont icon-article"></i>联系我们</h2>
                  <router-link :to="{path:'/article/page',query:{categoryId:''}}" tag="a" class="more">more&gt;</router-link>
                </div>
                <div class="ab-bd">
                  <ul class="list" v-for="(item, index) in list5" :key="index">
                    <li>
                      <router-link :to="{path:'/article/view',query:{id:item.id}}" target="_blank" tag="a">
                        {{item.title}}
                      </router-link>
                      <span class="time">{{item.createTime}}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="article-box">
                <div class="ab-hd">
                  <h2><i class="iconfont icon-article"></i>服务保证</h2>
                  <router-link :to="{path:'/article/page',query:{categoryId:''}}" tag="a" class="more">more&gt;</router-link>
                </div>
                <div class="ab-bd">
                  <ul class="list" v-for="(item, index) in list6" :key="index">
                    <li>
                      <router-link :to="{path:'/article/view',query:{id:item.id}}" target="_blank" tag="a">
                        {{item.title}}
                      </router-link>
                      <span class="time">{{item.createTime}}</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="article-box">
                <div class="ab-hd">
                  <h2><i class="iconfont icon-article"></i>会员中心</h2>
                  <router-link :to="{path:'/article/page',query:{categoryId:''}}" tag="a" class="more">more&gt;</router-link>
                </div>
                <div class="ab-bd">
                  <ul class="list" v-for="(item, index) in list7" :key="index">
                    <li>
                      <router-link :to="{path:'/article/view',query:{id:item.id}}" target="_blank" tag="a">
                        {{item.title}}
                      </router-link>
                      <span class="time">{{item.createTime}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>


    <Footer/>
  </div>

</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
import {getArticlePaging} from '@/api/article'
export default {
  name: "index",
  data() {
    return {
      articles:[],
      list1: [],
      list2: [],
      list3: [],
      list4: [],
      list5: [],
      list6: [],
      list7: []
    }
  },
  components: {
    Header,
    Footer
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      getArticlePaging({pageSize:5,currentPage:1,categoryId:'467133987110868101'}).then(res => {
        this.list1 = res.data.list
      });
      getArticlePaging({pageSize:5,currentPage:1,categoryId:'467134030589023365'}).then(res => {
        this.list2 = res.data.list
      });
      getArticlePaging({pageSize:5,currentPage:1,categoryId:'467127377076507397'}).then(res => {
        this.list3 = res.data.list
      });
      getArticlePaging({pageSize:5,currentPage:1,categoryId:'467130134068024261'}).then(res => {
        this.list4 = res.data.list
      });
      getArticlePaging({pageSize:5,currentPage:1,categoryId:'467130215269749701'}).then(res => {
        this.list5 = res.data.list
      });
      getArticlePaging({pageSize:5,currentPage:1,categoryId:'467130273889342405'}).then(res => {
        this.list6 = res.data.list
      });
      getArticlePaging({pageSize:5,currentPage:1,categoryId:'468274427495274693'}).then(res => {
        this.list7 = res.data.list
      });
    },
    getCoverImage(images){
      return images.length <= 0 ? this.staticUrl + '/app/no-pic.png' : this.staticUrl + '/' + images[0].url
    },
  }
}
</script>

<style scoped>

</style>
